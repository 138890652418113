import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { LoginService } from '../core/login.service';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'is-what';

import { Model } from '../model/model';
import { DataTable } from '../model/dataTable';

import { LookupDataService } from '../services/lookup-data.service';
import { DashboardService } from '../services/dashboard.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

    reportProgress: boolean = false;
    userID: string;    
    msg: string = "";

    viewReport: boolean = false;

    years: any[];
    modelType: any[];
    transmission: any[];

    selectedYear: string;
    selectedModelType: string;
    selectedTransmission: string;
    selectedModel: string;

    dataTable: DataTable;

    model = new Model('', '', '', '', '');

    constructor(private sanitizer: DomSanitizer,
        public loginService: LoginService,
        private lookupService: LookupDataService,
        private dashboardService: DashboardService,
        private router: Router,
        private titleService: Title) {
        this.titleService.setTitle("Dashboard");
        this.getModelYears();
        this.getModelType();
        this.getTransmissions();
    }

    ngOnInit() {
        const userSession = this.loginService.getUserSession();
        this.userID = this.loginService.getUserSession()['userName'];
        if (isNullOrUndefined(userSession)) {
            this.router.navigate(['login']);
        }
    }

    getModelYears() {
        if (!this.years) {
            this.getLookupData('modelYear').subscribe(resp => {
                this.years = resp;
            });
        }
    }

    getModelType() {
        if (!this.modelType) {
            this.getLookupData('modelType').subscribe(resp => {
                this.modelType = resp;
            });
        }
    }

    getTransmissions() {
        if (!this.transmission) {
            this.getLookupData('transmission').subscribe(resp => {
                this.transmission = resp;
            });
        }
    }

    getLookupData(type: string) {
        return this.lookupService.getLookupData(type);
    }

    changeYear(event, type) {
        console.log("ID: " + event + ", Type: " + type);
        console.log("ModelYear: " + this.model.modelYear);

        this.selectedYear = this.model.modelYear;
        if (this.model.modelYear != null) {
            this.lookupService.getModelTypeLookupDataByYear(this.model.modelYear).subscribe(resp => {
                this.modelType = resp;
            });
        }
    }

    changeModelType(event, type) {
        console.log("ID: " + event + ", Type: " + type);
        console.log("ModelYear: " + this.model.modelYear);
        console.log("Model Type: " + this.model.modelType);

        this.selectedModel = this.model.modelType.substring(this.model.modelType.indexOf("-")+2);

        if (this.modelType != null) {
            for (var i in this.modelType) {
                if (this.model.modelType === this.modelType[i].value) {
                    this.selectedModelType = this.modelType[i].field;                    
                    console.log("Inside Change Model Type Value: " + this.modelType[i].value + ", : Field: " + this.modelType[i].field
                        + ", : Model Type Local Variable: " + this.selectedModelType);
                }
            }
        }

        if (this.selectedYear != null && this.selectedModelType != null) {
            this.lookupService.getTransmissionLookupDataByYearAndType(this.selectedYear, this.selectedModelType).subscribe(resp => {
                this.transmission = resp;
            });
        }
    }

    changeTransmission(event, type) {
        console.log("ID: " + event + ", Type: " + type);
        console.log("ModelYear: " + this.model.modelYear);
        console.log("Model Type: " + this.model.modelType);
        console.log("Model Description: " + this.selectedModel);
        console.log("Transmission: " + this.model.transmission);

        if (this.transmission != null) {
            for (var i in this.transmission) {
                if (this.model.transmission === this.transmission[i].value) {
                    this.selectedTransmission = this.transmission[i].field;
                    console.log("Inside Change Transmission Value: " + this.transmission[i].value + ", : Field: " + this.transmission[i].field
                        + ", : Transmission Local Variable: " + this.selectedTransmission);
                }
            }
        }        
    }

    clearEntries() {
        this.model.modelType = "";
        this.model.modelYear = "";
        this.model.transmission = "";        
        this.selectedYear = "";
        this.selectedModelType = "";
        this.selectedModel = "";
        this.selectedTransmission = "";
        this.msg = "";
        this.viewReport = false;
    }

    async view() {
        this.msg = "";
        this.reportProgress = true;
        this.viewReport = false;

        console.log("Selected ModelYear: " + this.selectedYear);
        console.log("Selected Model Description: " + this.selectedModel);
        console.log("Selected Transmission: " + this.selectedTransmission);

        if (this.selectedYear != null) {
            const dashboardData = await this.dashboardService.getDashboardData(this.selectedYear, this.selectedModel, this.selectedTransmission);
            const dataTable = new DataTable();
            dataTable.name = "Dashboard Content";
            dataTable.columns = [
                { field: 'ModelYear', header: 'Model Year', dataType: "string" },
                { field: 'ModelType', header: 'Model Type', dataType: "string" },
                { field: 'ModelDescription', header: 'Model Description', dataType: "string" },
                { field: 'TransmissionDescription', header: 'Transmission Description', dataType: "string" },                
                { field: 'TriggerFlag', header: 'Trigger Model', dataType: "string" },                
                { field: 'PointofSaleDate', header: 'Point of Sale Date', dataType: "string" },
                { field: 'FEUpdate', header: 'Fuel Economy Update', dataType: "string" },
                { field: 'FEApprove', header: 'Fuel Economy Approve', dataType: "string" },
                { field: 'PartsContentUpdate', header: 'Parts Content Update', dataType: "string" },
                { field: 'PartsContentApprove', header: 'Parts Content Approve', dataType: "string" },
                { field: 'InvoiceTesting', header: 'Invoice Testing', dataType: "string" },
                { field: 'ReleaseFlag', header: 'Release Model', dataType: "string" }
            ];
            dataTable.records = dashboardData.dashboardColumns;
            dataTable.records = dataTable.records.map(dc => {
                return {
                    ModelYear: dc.ModelYear,
                    ModelType: dc.ModelType,
                    ModelDescription: dc.ModelDescription,
                    TransmissionDescription: dc.TransmissionDescription,
                    TriggerFlag: dc.TriggerFlag,
                    PointofSaleDate: dc.PointofSaleDate == null ? null : new Date(dc.PointofSaleDate).toLocaleDateString('en-US'),
                    FEUpdate: dc.FEUpdate,
                    FEApprove: dc.FEApprove,
                    PartsContentUpdate: dc.PartsContentUpdate,
                    PartsContentApprove: dc.PartsContentApprove,
                    InvoiceTesting: dc.InvoiceTesting,
                    ReleaseFlag: dc.ReleaseFlag
                };
            });            
            this.dataTable = dataTable;
            this.viewReport = true;
            setTimeout(() => { this.reportProgress = false }, 500);
        }
    }
}