<main class="main">
    <div class="main-content" style="padding: 10px;">
        <h3>Product Content</h3>
        <form class="report-form">
            <div class="row">
                <div class="form-group col-sm-2">
                    <label for="year">Model Year</label>
                    <datalist id="year" name="year">
                        <option *ngFor="let yr of years; let i = index;" [value]="yr.field">{{yr.value}}</option>
                    </datalist>
                    <input autocomplete="on" class="form-control" placeholder="Select the Year" id="year" name="year"
                        #select list="year" [(ngModel)]="model.modelYear" [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="changeYear($event, 'year')" />
                </div>

                <div class="form-group col-sm-2">
                    <label for="modelType">Model Type</label>
                    <datalist id="modelType" name="modelType">
                        <option *ngFor="let model of modelType; let i = index;" [value]="model.value">{{model.value}}
                        </option>
                    </datalist>
                    <input autocomplete="on" class="form-control" placeholder="Select the Model Type" id="modelType"
                        name="modelType" #select list="modelType" [(ngModel)]="model.modelType"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="changeModelType($event, 'type')" />
                </div>

                <div class="form-group col-sm-2">
                    <label for="transmission">Transmission</label>
                    <datalist id="transmission" name="transmission">
                        <option *ngFor="let trns of transmission; let i = index;" [value]="trns.value">{{trns.value}}
                        </option>
                    </datalist>
                    <input autocomplete="on" class="form-control" placeholder="Select the Transmission"
                        id="transmission" name="transmission" #select list="transmission"
                        [(ngModel)]="model.transmission" [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="changeTransmission($event, 'transmission')" />
                </div>

                <div class="form-group col-sm-2">
                    <label for="vin">VIN</label>
                    <!-- <datalist id="vin" name="vin">
                        <option *ngFor="let vin of vins; let i = index;" [value]="vin.field">{{vin.value}}
                        </option>
                    </datalist>
                    <input autocomplete="on" class="form-control" placeholder="Select the VIN"
                        id="vin" name="vin" #select list="vin"
                        [(ngModel)]="selectedVIN" [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="changeVIN($event, 'vin')" /> -->

                    <input class="form-control" name="vin" type="text" [(ngModel)]="selectedVIN" value="{{selectedVIN}}"
                        (ngModelChange)="changeVIN($event, 'vin')" placeholder="Enter VIN">
                </div>

                <div class="form-group col-sm report-button-container">
                    <button [disabled]="!model.modelYear || !model.modelType || !model.transmission || !selectedVIN"
                        class="btn btn-primary" type="submit" iconPos="right" (click)="showReport()">View <em
                            *ngIf="reportProgress" class="fas fa-spinner fa-spin"></em></button>
                    &nbsp;&nbsp;
                    <button class="btn btn-primary" type="submit" iconPos="right" (click)="clearEntries()">Clear All
                        Filters</button>
                </div>
            </div>
            <div *ngIf="msg.length>0" [hidden]="msg.length<1"
                class="alert alert-danger alert-dismissible fade show w-50" role="alert">
                {{msg}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </form>

        <div class="container-fluid" *ngIf="viewReport">
            <h5>Click on Approve Data, if the fields are correct </h5>
            <div class="report-button-container">
                <button id="approveButton" class="btn btn-primary" type="submit"
                    [disabled]="dataTable.records.length <= 0 || dataTable.approveFlag === 'Y'" iconPos="right"
                    (click)="approveData()">Approve Data <em *ngIf="approveProgress"
                        class="fas fa-spinner fa-spin"></em></button>
            </div>
            <div style="padding-left: 775px;" class="report-button-container">
                <button id="labelButton" class="btn btn-primary" type="submit" iconPos="right"
                (click)="viewLabel()">View Label Proof <em *ngIf="labelProgress"
                    class="fas fa-spinner fa-spin"></em></button>
            </div>
            <h5 style="padding-left: 775px;">Point of Sale Date: {{posDate}}</h5>
            <div *ngIf="reportProgress" class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div>
                <p><span style="font-weight: bold;">Transmission Description:</span> {{transmissionDescription}}</p>

                <p><span style="font-weight: bold;">Motor:</span> {{motor}}</p>
                
                <p><span style="font-weight: bold;">Standard Horsepower / Torque:</span> {{horsePower}}</p>
            </div>
            <span>No. of Records: {{dataTable.records.length}}</span>
            <table *ngIf="dataTable" class="table">
                <thead class="thead-light">
                    <tr>
                        <th [ngClass]="{'table-title': i===0, 'table-header': i !== 0}"
                            [style.width]="width == null ? '' : width[i]"
                            *ngFor="let column of dataTable.columns; index as i;" scope="col">
                            {{column.header}}
                        </th>
                    </tr>
                </thead>
                <tbody class="table-text">
                    <ng-container *ngIf="dataTable.records.length > 0">
                        <tr *ngFor="let row of dataTable.records; index as i2;">
                            <td scope="row" id="{{column.dataType}}" [ngClass]="{'center-text' : i!==0}"
                                [style.width]="width == null ? '' : width[i]"
                                *ngFor="let column of dataTable.columns; index as i;">
                                <span *ngIf="column.dataType!='number'">{{row[column.field]}}</span>
                                <span *ngIf="column.dataType=='number'">{{row[column.field] |
                                    currency:'USD':'symbol'}}</span>
                            </td>
                        </tr>
                    </ng-container>

                    <tr>
                        <ng-container *ngIf="dataTable.records.length==0">
                            <td scope="row" class="text-center">
                                No product content data available.
                            </td>
                        </ng-container>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th>Total</th>
                        <th colspan="2"></th>
                        <th style="text-align: right;">{{dataTable.finalTotal | currency:'USD':'symbol'}}</th>
                    </tr>
                </tfoot>
            </table>
        </div>

    </div>
</main>