import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { LoginService } from '../core/login.service';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'is-what';

import { Model } from '../model/model';
import { FuelEconomy } from '../model/fuelEconomy';
import { EmailProperties } from '../model/emailProperties';

import { LookupDataService } from '../services/lookup-data.service';
import { FuelEconomyContentService } from '../services/fuelEconomyContent.service';

import { EmailService } from '../services/email.service';
import { APIService } from '../services/api.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-fuelEconomy',
    templateUrl: './fuelEconomy.component.html',
    styleUrls: ['./fuelEconomy.component.css']
})
export class FuelEconomyComponent implements OnInit {

    reportProgress: boolean = false;
    updateProgress: boolean = false;
    approveProgress: boolean = false;
    labelProgress: boolean = false;

    showFields: boolean = false;

    msg: string = "";

    years: any[];
    modelType: any[];
    transmission: any[];
    ftype: any[];
    options: any[];

    fuelEconomy: FuelEconomy;
    model = new Model('', '', '', '', '');
    emailProperties: EmailProperties;

    selectedYear: string;
    selectedModelType: string;
    selectedTransmission: string;
    selectedFuelType: string;
    selectedOptions: string;

    userID: string;

    constructor(private sanitizer: DomSanitizer,
        public loginService: LoginService,
        private lookupService: LookupDataService,
        private fuelEconomyService: FuelEconomyContentService,
        private emailService: EmailService,
        private apiService: APIService,
        private router: Router,
        private titleService: Title) {
        this.titleService.setTitle("Fuel Economy");
        this.getModelYears();
        this.getModelType();
        this.getTransmissions();
        this.getFuelType();
        this.getOptions();
    }


    ngOnInit() {
        const userSession = this.loginService.getUserSession();
        this.userID = this.loginService.getUserSession()['userName'];
        if (isNullOrUndefined(userSession)) {
            this.router.navigate(['login']);
        }
    }

    getModelYears() {
        if (!this.years) {
            this.getLookupData('modelYear').subscribe(resp => {
                this.years = resp;
            });
        }
    }

    getModelType() {
        if (!this.modelType) {
            this.getLookupData('modelType').subscribe(resp => {
                this.modelType = resp;
            });
        }
    }

    getTransmissions() {
        if (!this.transmission) {
            this.getLookupData('transmission').subscribe(resp => {
                this.transmission = resp;
            });
        }
    }

    getFuelType() {
        if (!this.ftype) {
            // this.ftype = [{ field: "GH", value: "Gasoline" },
            // { field: "PHEV", value: "Electricity + Gasoline" },
            // { field: "BEV", value: "Electricity" }
            // ];
            this.getLookupData('fuelType').subscribe(resp => {
                this.ftype = resp;
            });
        }
    }

    getOptions() {
        if (!this.options) {
            this.getLookupData('options').subscribe(resp => {
                this.options = resp;
            });
        }
    }

    getLookupData(type: string) {
        return this.lookupService.getLookupData(type);
    }

    changeYear(event, type) {
        console.log("ID: " + event + ", Type: " + type);

        this.selectedYear = this.model.modelYear;
        if (this.model.modelYear != null) {
            this.lookupService.getModelTypeLookupDataByYear(this.model.modelYear).subscribe(resp => {
                this.modelType = resp;
            });
        }
    }

    changeModelType(event, type) {
        console.log("ID: " + event + ", Type: " + type);

        if (this.modelType != null) {
            for (var i in this.modelType) {
                if (this.model.modelType === this.modelType[i].value) {
                    this.selectedModelType = this.modelType[i].field;
                }
            }
        }

        if (this.selectedYear != null && this.selectedModelType != null) {
            this.lookupService.getTransmissionLookupDataByYearAndType(this.selectedYear, this.selectedModelType).subscribe(resp => {
                this.transmission = resp;
            });
        }
    }

    changeTransmission(event, type) {
        console.log("ID: " + event + ", Type: " + type);

        if (this.transmission != null) {
            for (var i in this.transmission) {
                if (this.model.transmission === this.transmission[i].value) {
                    this.selectedTransmission = this.transmission[i].field;
                }
            }
        }

        if (this.selectedYear != null && this.selectedModelType != null && this.selectedTransmission != null) {
            this.lookupService.getFuelTypeLookupDataByYearTypeAndTransmission(this.model.modelYear, this.selectedModelType, this.selectedTransmission).subscribe(resp => {
                this.ftype = resp;
            });
        }

    }

    changeFuelType(event, type) {
        console.log("ID: " + event + ", Type: " + type);

        if (this.ftype != null) {
            for (var i in this.ftype) {
                if (this.model.fuelType === this.ftype[i].value) {
                    this.selectedFuelType = this.ftype[i].field;
                }
            }
        }

        if (this.selectedYear != null && this.selectedModelType != null && this.selectedTransmission != null && this.selectedFuelType != null) {
            this.lookupService.getOptionsLookupDataByYearTypeTransmAndFuelType(this.model.modelYear, this.selectedModelType, this.selectedTransmission, this.selectedFuelType).subscribe(resp => {
                this.options = resp;
            });
        }
    }

    changeOptions(event, type) {
        console.log("ID: " + event + ", Type: " + type);

        if (this.options != null) {
            for (var i in this.options) {
                if (this.model.options === this.options[i].value) {
                    this.selectedOptions = this.options[i].field;
                }
            }
        }
        this.getPosDate(this.selectedYear, this.selectedModelType, this.selectedTransmission);
    }

    clearEntries() {
        this.model.modelType = "";
        this.model.modelYear = "";
        this.model.transmission = "";
        this.model.fuelType = "";
        this.model.options = "";
        this.selectedYear = "";
        this.selectedModelType = "";
        this.selectedTransmission = "";
        this.selectedFuelType = "";
        this.selectedOptions = "";
        this.msg = "";
        this.to = "";
        this.from = "";
        this.subject = "";
        this.message = "";
        this.cc = "";
        this.bcc = "";
        this.releaseEmails = "";
        this.posDate = "";
        this.showFields = false;
    }

    posDate: string;
    getPosDate(selectedYear, selectedModelType, selectedTransmission) {
        this.lookupService.getPOSDate(selectedYear, selectedModelType, selectedTransmission).then((posDate) => {
            this.posDate = posDate;
        });
    }

    async view() {
        this.msg = "";
        this.reportProgress = true;
        console.log("Selected ModelYear: " + this.selectedYear);
        console.log("Selected Model Type: " + this.selectedModelType);
        console.log("Selected Transmission: " + this.selectedTransmission);
        console.log("Selected Fuel Type: " + this.selectedFuelType);
        console.log("Selected Options: " + this.selectedOptions);

        if (this.selectedYear != null && this.selectedModelType != null && this.selectedTransmission != null && this.selectedFuelType != null && this.selectedOptions != null) {
            this.fuelEconomy = await this.fuelEconomyService.getFuelEconomyContent(this.selectedYear, this.selectedModelType, this.selectedTransmission, this.selectedFuelType, this.selectedOptions);
            if (this.fuelEconomy.errors != null) {
                this.msg = "No Data available. Add the data using the form below.";
                this.showFields = false;
                return;
            }
            this.reportProgress = false;
            this.showFields = true;
        }

        if (this.selectedYear <= '2023') {
            //this will disable the approve and update buttons
            this.fuelEconomy.updateFlag = 'Y';
            this.fuelEconomy.approveFlag = 'Y';
        }
        setTimeout(() => { this.reportProgress = false }, 500);
    }

    async getEmailProperties(emailType) {
        console.log("emailType: " + emailType);
        this.to = "";
        this.from = "";
        this.subject = "";
        this.message = "";
        this.cc = "";
        this.bcc = "";
        this.releaseEmails = "";
        (await this.emailService.getEmailProperties(emailType)).subscribe((properties) => {
            this.emailProperties = properties;

            if (this.emailProperties.errors != null) {
                this.msg = "Error Fetching email properties";
            }

            this.from = this.emailProperties.emailFrom;
            this.subject = this.emailProperties.emailSubject;
            this.message = this.emailProperties.emailBody;
            this.releaseEmails = this.emailProperties.releaseEmails;
            this.cc = this.emailProperties.emailCCTo;
            this.bcc = this.emailProperties.emailBCCTo;

            if (this.releaseEmails === "Y") {
                this.to = this.emailProperties.emailTo;
            } else {
                console.log("checking")
                this.to = this.bcc;
            }

            console.log("From the method: " + this.releaseEmails + " ,: " + this.to + " ,: " + this.cc + " ,: " + this.bcc + " ,: " + this.subject + " ,: " + this.message);
        });
    }

    to: string;
    from: string;
    subject: string;
    message: string;
    cc: string;
    bcc: string;
    releaseEmails: string;

    async updateData() {
        this.updateProgress = true;
        this.msg = "";
        this.getEmailProperties('FuelEconomyUpdate');

        this.fuelEconomyService.updateFuelEconomyData(
            {
                combinedFuelEconomy: this.fuelEconomy.combinedFuelEconomy
                , cityFuelEconomy: this.fuelEconomy.cityFuelEconomy
                , hwyFuelEconomy: this.fuelEconomy.hwyFuelEconomy
                , fuelConsumption: this.fuelEconomy.fuelConsumption
                , vehicleType: this.fuelEconomy.vehicleType
                , vehicleTypeRange: this.fuelEconomy.vehicleTypeRange
                , vehicleTypeRangeMetric: this.fuelEconomy.vehicleTypeRangeMetric
                , annualFuelCostSavings: this.fuelEconomy.annualFuelCostSavings
                , annualFuelCost: this.fuelEconomy.annualFuelCost
                , costPerGallon: this.fuelEconomy.costPerGallon
                , feRating: this.fuelEconomy.feRating
                , c02Value: this.fuelEconomy.c02Value
                , smogRating: this.fuelEconomy.smogRating
                , cityFuelEconomyLiters: this.fuelEconomy.cityFuelEconomyLiters
                , hwyFuelEconomyLiters: this.fuelEconomy.hwyFuelEconomyLiters
                , altFuelType: this.fuelEconomy.altFuelType
                , altFuelEconomy: this.fuelEconomy.altFuelEconomy
                , altFuelConsumption: this.fuelEconomy.altFuelConsumption
                , chargeTime: this.fuelEconomy.chargeTime
                , altCostPer: this.fuelEconomy.altCostPer
                , range: this.fuelEconomy.range
                , altRange: this.fuelEconomy.altRange
                , subpoint1: this.fuelEconomy.subpoint1
                , subpoint2: this.fuelEconomy.subpoint2
                , subpoint3: this.fuelEconomy.subpoint3
                , subpoint4: this.fuelEconomy.subpoint4
                , c02Rating: this.fuelEconomy.c02Rating
                , gasGuzzlerTax: this.fuelEconomy.gasGuzzlerTax
                , qrCode: this.fuelEconomy.qrCode
                , convertibleFlag: this.fuelEconomy.convertibleFlag
                , altFuelConsumption1: this.fuelEconomy.altFuelConsumption1
                , altFuelConsumption2: this.fuelEconomy.altFuelConsumption2
                , modelYear: this.selectedYear
                , modelType: this.selectedModelType
                , transmissionID: this.selectedTransmission
                , fuelType: this.selectedFuelType
                , options: this.selectedOptions
                , userID: this.userID
            }).then((result) => {
                console.log("result: ", result);
                if (result) {
                    this.msg = "Update complete.";
                    let button = <HTMLButtonElement>document.getElementById('updateButton');
                    button.disabled = true;
                    // this.view();

                    console.log("Outside of method: " + this.releaseEmails + " ,: " + this.to + " ,: " + this.cc + " ,: " + this.bcc + " ,: " + this.subject + " ,: " + this.message);
                    if (this.to != null && this.to != 'undefined') {
                        let message = "Model Year: " + this.selectedYear + ", Model Type: " + this.selectedModelType + ", Transmission: " + this.model.transmission + ", Fuel Type: " + this.model.fuelType + ", Options: " + this.model.options;
                        this.message = message + " " + this.message;
                        this.emailService.sendEmail(this.from, this.to, this.subject, this.message, this.cc, this.bcc).then((res) => {
                            console.log("Email response from Update: " + res);
                        });
                    }
                }
                else
                    this.msg = "Update Failed.";
            });
        setTimeout(() => { this.updateProgress = false }, 500);
    }

    async approveData() {
        this.approveProgress = true;
        this.msg = "";
        this.getEmailProperties('FuelEconomyApproval');

        this.fuelEconomyService.approveFuelEconomyData(this.selectedYear, this.selectedModelType, this.selectedTransmission, this.selectedFuelType, this.selectedOptions).then((result) => {
            if (result) {
                this.msg = "Approval complete.";
                let button = <HTMLButtonElement>document.getElementById('approveButton');
                button.disabled = true;

                console.log("Outside of method: " + this.releaseEmails + " ,: " + this.to + " ,: " + this.cc + " ,: " + this.bcc + " ,: " + this.subject + " ,: " + this.message);
                if (this.to != null && this.to != 'undefined') {
                    let message = "Model Year: " + this.selectedYear + ", Model Type: " + this.selectedModelType + ", Transmission: " + this.model.transmission + ", Fuel Type: " + this.model.fuelType + ", Options: " + this.model.options;
                    this.message = message + " " + this.message;
                    this.emailService.sendEmail(this.from, this.to, this.subject, this.message, this.cc, this.bcc).then((res) => {
                        console.log("Email response from Approve: " + res);
                    });
                }
            }
            else
                this.msg = "Approval Failed.";
        });
        setTimeout(() => { this.approveProgress = false }, 500);
    }

    async viewLabel() {
        this.labelProgress = true;
        this.msg = "";

        // if(this.selectedYear == '2024' && this.selectedModelType == '982320' && this.selectedTransmission == '2207' && this.selectedFuelType == 'GV' && this.selectedOptions == 'DEFAULT') {  //GV, DEFAULT
        //     window.open('../assets/Fuel_Economy_2024_718_Boxster.pdf', '_blank');
        // } else if (this.selectedYear == '2024' && this.selectedModelType == '982110' && this.selectedTransmission == '2202' && this.selectedFuelType == 'GV' && this.selectedOptions == 'DEFAULT') {   //GV, DEFAULT
        //     window.open('../assets/FE_Caymann_Style_Edition.pdf', '_blank');
        // } else if (this.selectedYear == '2024' && this.selectedModelType == '95BBV1' && this.selectedTransmission == '2200' && this.selectedFuelType == 'GV' && this.selectedOptions == 'DEFAULT') {  //GV, DEFAULT
        //     window.open('../assets/FE_MacanS_Gasoline.pdf', '_blank');
        // } else if (this.selectedYear == '2024' && this.selectedModelType == '992110' && this.selectedTransmission == '2211' && this.selectedFuelType == 'GV' && this.selectedOptions == 'DEFAULT') {  //GV, DEFAULT
        //     window.open('../assets/FE_911_Carrera.pdf', '_blank');
        // } else if (this.selectedYear == '2024' && this.selectedModelType == 'Y1AAA1' && this.selectedTransmission == '2250' && this.selectedFuelType == 'BEV' && this.selectedOptions == 'J9K') {  //BEV, J9K
        //     window.open('../assets/FE_Taycan_2Speed_BEV.pdf', '_blank');
        // }
        // setTimeout(() => { this.labelProgress = false }, 500);

        await this.apiService.getLabelProofForFE(environment.fuelEconomyTemplateID, this.selectedYear, this.selectedModelType, this.selectedTransmission, this.selectedFuelType, this.selectedOptions).subscribe((res) => {
            console.log(res);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(res);
            var dd = new Date;
            var date = new Date().getFullYear() + "-" + (dd.getMonth() + 1) + "-" + dd.getDate();
            var seconds = dd.getTime() / 1000;
            var fileName = "Fuel_Economy" + this.selectedYear + "_" + this.selectedModelType + "_" + date + "_" + seconds.toString() + ".pdf";
            // link.setAttribute('download', fileName);
            link.setAttribute('target', '_blank');
            link.click();            
            this.labelProgress = false;
        }), error => {
            console.log("Error occured with the API response: " + error);
        };        
    }
}
