<main class="main">
    <div class="main-content" style="padding: 10px;">
        <h3>Admin Page</h3>
        <form class="report-form">
            <div class="row">
                <div class="form-group col-sm-2">
                    <label for="year">Model Year</label>
                    <datalist id="year" name="year">
                        <option *ngFor="let yr of years; let i = index;" [value]="yr.field">{{yr.value}}</option>
                    </datalist>
                    <input autocomplete="on" class="form-control" placeholder="Select the Year" id="year" name="year"
                        #select list="year" [(ngModel)]="model.modelYear" [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="changeYear($event, 'year')" />
                </div>

                <div class="form-group col-sm-2">
                    <label for="modelType">Model Type</label>
                    <datalist id="modelType" name="modelType">
                        <option *ngFor="let model of modelType; let i = index;" [value]="model.value">{{model.value}}
                        </option>
                    </datalist>
                    <input autocomplete="on" class="form-control" placeholder="Select the Model Type" id="modelType"
                        name="modelType" #select list="modelType" [(ngModel)]="model.modelType"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="changeModelType($event, 'type')" />
                </div>

                <div class="form-group col-sm-2">
                    <label for="transmission">Transmission</label>
                    <datalist id="transmission" name="transmission">
                        <option *ngFor="let trns of transmission; let i = index;" [value]="trns.value">{{trns.value}}
                        </option>
                    </datalist>
                    <input autocomplete="on" class="form-control" placeholder="Select the Transmission"
                        id="transmission" name="transmission" #select list="transmission"
                        [(ngModel)]="model.transmission" [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="changeTransmission($event, 'transmission')" />
                </div>

                <div class="form-group col-sm report-button-container">
                    <button [disabled]="!model.modelYear || !model.modelType || !model.transmission"
                        class="btn btn-primary" type="submit" iconPos="right" (click)="view()">View <em
                            *ngIf="reportProgress" class="fas fa-spinner fa-spin"></em></button>
                    &nbsp;&nbsp;
                    <button class="btn btn-primary" type="submit" iconPos="right" (click)="clearEntries()">Clear All
                        Filters</button>
                </div>
            </div>
            <div *ngIf="msg.length>0" [hidden]="msg.length<1"
                class="alert alert-danger alert-dismissible fade show w-50" role="alert">
                {{msg}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

        </form>

        <div class="container-fluid" *ngIf="showFields">
            <div class="form-row">
                <div class="col-md-3 border rounded">                    
                    <div class="form-row">
                        <div class="flex flex-column gap-3 form-group">
                            <label for="trigger">Trigger Model: </label>
                            <div *ngFor="let choice of choices" class="field-checkbox">
                                <p-radioButton [inputId]="choice.key" name="triggerChoice" [value]="choice.key"
                                    [(ngModel)]="selectedTriggerChoice" />
                                <label [for]="choice.key" class="ml-2">
                                    {{ choice.value }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="flex flex-column gap-3 form-group">
                            <label for="invoiceTesting">Invoice Testing: </label>
                            <div *ngFor="let choice of choices" class="field-checkbox">
                                <p-radioButton [inputId]="choice.key" name="testingChoice" [value]="choice.key"
                                    [(ngModel)]="selectedTestingChoice" />
                                <label [for]="choice.key" class="ml-2">
                                    {{ choice.value }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="flex flex-column gap-3 form-group">
                            <label for="release">Release Model: </label>
                            <div *ngFor="let choice of choices" class="field-checkbox">
                                <p-radioButton [inputId]="choice.key" name="releaseChoice" [value]="choice.key"
                                    [(ngModel)]="selectedReleaseChoice" />
                                <label [for]="choice.key" class="ml-2">
                                    {{ choice.value }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group">
                            <label for="posDate">Point of Sale Date: </label>
                            <p-calendar id="posDate" dateFormat="mm/dd/yy" selectOtherMonths="true"
                                [(ngModel)]="selectedPOSDate" [style]="{'width':'80%'}" [inputStyle]="{'width':'80%'}" class="posDateCalendar"
                                dataType="string" inputId="posDate" [showButtonBar]="true"></p-calendar>
                        </div>
                    </div>
                    <div class="form-row my-1">
                        <button class="btn btn-primary" id="updateButton" type="submit"
                            [disabled]="adminPage.isUpdateValid === 'N'" iconPos="right" (click)="updateData()">Update
                            <em *ngIf="updateProgress" class="fas fa-spinner fa-spin"></em></button>
                    </div>
                </div>

                <!-- <div class="col-md-4 border rounded">
                    <div class="form-row">
                        <div class="form-group">
                            <label for="posDate">Upload Silhouette: </label>
                            <p-fileUpload name="silhouette[]" url="./upload.php" (onUpload)="onUpload($event)"
                                multiple="multiple" maxFileSize="10000000">                                
                                <ng-template pTemplate="content">
                                    <ul *ngIf="uploadedFiles.length">
                                        <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
                                    </ul>
                                </ng-template>
                            </p-fileUpload>
                        </div>
                    </div>
                </div> -->
            </div>

        </div>
    </div>
</main>