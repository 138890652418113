import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'is-what';
import { LoginService } from '../login.service';
import { AuthenticationService } from '../authentication.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public loginErrorText: string = '';
  public loginProgress: boolean = false;
  public loginError: boolean = false;
  public user: any = {};
  public loggedInUserName: string = '';

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    private authenticationService: AuthenticationService,
    private titleService: Title) {
    this.titleService.setTitle("Porsche - Login");
  }

  public ngOnInit() {
    this.loginErrorText = '';
    if (!isNullOrUndefined(this.activatedRoute.snapshot.queryParams['logout'])) {
      this.loginError = true;
      if (this.activatedRoute.snapshot.queryParams['logout'] == 'error')
        this.loginErrorText = 'Your session has expired. Please login again to continue';
      else if (this.activatedRoute.snapshot.queryParams['logout'] == 'success')
        this.loginErrorText = 'Logout successful. Please login again to continue';
    }
    if (!isNullOrUndefined(sessionStorage.getItem('userSession'))) {
      this.router.navigate(['home']);
    }
  }

  async login() {
    this.loginProgress = true;
    this.loginError = false;
    try {
      let token = await this.authenticationService.login(this.user.username, this.user.password);
      if (token != null) {
        this.loginService.setTokenInSession(token);
        // this.loginService.setUserSession(token);
        this.loginService.setLogin();
        // this.loginService.setOriginalUserSession(token);

        let userRoles = [];
        this.loginService.getUserRoles(this.user.username).subscribe(resp => {
          userRoles = resp;
          this.loginService.setUserRolesInSession(userRoles);
        });

        this.loginService.getLoggedInUserName(this.user.username).then((resp) => {          
          this.loginService.setUserFullNameInSession(resp);
        });

        this.router.navigateByUrl('/home');
      }
    } catch (error) {
      console.log("There was an error...." + error);
      this.handleError(error);
    }
  }

  private handleError(error: string) {
    this.loginProgress = false;
    this.loginError = true;
    this.loginErrorText = error;
    sessionStorage.clear();
  }

}