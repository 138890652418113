import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { LoginService } from '../core/login.service';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'is-what';

import { Model } from '../model/model';
import { LookupDataService } from '../services/lookup-data.service';
import { AdminPageService } from '../services/adminPage.service';
import { AdminPage } from '../model/adminPage';

@Component({
    selector: 'app-adminPage',
    templateUrl: './adminPage.component.html',
    styleUrls: ['./adminPage.component.css']
})
export class AdminPageComponent implements OnInit {

    reportProgress: boolean = false;
    updateProgress: boolean = false;
    userID: string;

    showFields: boolean = false;
    msg: string = "";

    years: any[];
    modelType: any[];
    transmission: any[];

    selectedYear: string;
    selectedModelType: string;
    selectedTransmission: string;

    selectedReleaseChoice;
    selectedTriggerChoice;
    selectedTestingChoice;
    selectedPOSDate;

    choices: any[] = [
        { key: 'Y', value: 'Yes' },
        { key: 'N', value: 'No' }
    ]

    adminPage: AdminPage;

    model = new Model('', '', '', '', '');

    constructor(private sanitizer: DomSanitizer,
        public loginService: LoginService,
        private lookupService: LookupDataService,
        private adminPageService: AdminPageService,
        private router: Router,
        private titleService: Title) {
        this.titleService.setTitle("Admin Page");
        this.getModelYears();
        this.getModelType();
        this.getTransmissions();
    }

    ngOnInit() {
        const userSession = this.loginService.getUserSession();
        this.userID = this.loginService.getUserSession()['userName'];
        if (isNullOrUndefined(userSession)) {
            this.router.navigate(['login']);
        }
    }

    getModelYears() {
        if (!this.years) {
            this.getLookupData('modelYear').subscribe(resp => {
                this.years = resp;
            });
        }
    }

    getModelType() {
        if (!this.modelType) {
            this.getLookupData('modelType').subscribe(resp => {
                this.modelType = resp;
            });
        }
    }

    getTransmissions() {
        if (!this.transmission) {
            this.getLookupData('transmission').subscribe(resp => {
                this.transmission = resp;
            });
        }
    }

    getLookupData(type: string) {
        return this.lookupService.getLookupData(type);
    }

    changeYear(event, type) {
        console.log("ID: " + event + ", Type: " + type);
        console.log("ModelYear: " + this.model.modelYear);

        this.selectedYear = this.model.modelYear;
        if (this.model.modelYear != null) {
            this.lookupService.getModelTypeLookupDataByYear(this.model.modelYear).subscribe(resp => {
                this.modelType = resp;
            });
        }
    }

    changeModelType(event, type) {
        console.log("ID: " + event + ", Type: " + type);
        console.log("ModelYear: " + this.model.modelYear);
        console.log("Model Type: " + this.model.modelType);

        if (this.modelType != null) {
            for (var i in this.modelType) {
                if (this.model.modelType === this.modelType[i].value) {
                    this.selectedModelType = this.modelType[i].field;
                    console.log("Inside Change Model Type Value: " + this.modelType[i].value + ", : Field: " + this.modelType[i].field
                        + ", : Model Type Local Variable: " + this.selectedModelType);
                }
            }
        }

        if (this.selectedYear != null && this.selectedModelType != null) {
            this.lookupService.getTransmissionLookupDataByYearAndType(this.selectedYear, this.selectedModelType).subscribe(resp => {
                this.transmission = resp;
            });
        }
    }

    changeTransmission(event, type) {
        console.log("ID: " + event + ", Type: " + type);
        console.log("ModelYear: " + this.model.modelYear);
        console.log("Model Type: " + this.model.modelType);
        console.log("Transmission: " + this.model.transmission);

        if (this.transmission != null) {
            for (var i in this.transmission) {
                if (this.model.transmission === this.transmission[i].value) {
                    this.selectedTransmission = this.transmission[i].field;
                    console.log("Inside Change Transmission Value: " + this.transmission[i].value + ", : Field: " + this.transmission[i].field
                        + ", : Transmission Local Variable: " + this.selectedTransmission);
                }
            }
        }
    }

    clearEntries() {
        this.model.modelType = "";
        this.model.modelYear = "";
        this.model.transmission = "";
        this.selectedYear = "";
        this.selectedModelType = "";
        this.selectedTransmission = "";
        this.msg = "";
        this.selectedReleaseChoice = "";
        this.selectedTriggerChoice = "";
        this.selectedTestingChoice = "";
        this.selectedPOSDate = null;        
        this.showFields = false;
    }

    isUpdateValid = "Y";

    async view() {
        this.msg = "";
        this.reportProgress = true;
        console.log("Selected ModelYear: " + this.selectedYear);
        console.log("Selected Model Type: " + this.selectedModelType);
        console.log("Selected Transmission: " + this.selectedTransmission);

        this.selectedReleaseChoice = "N";
        this.selectedTriggerChoice = "N";
        this.selectedTestingChoice = "N";
        this.selectedPOSDate = null;

        if (this.selectedYear != null && this.selectedModelType != null && this.selectedTransmission != null) {
            (await this.adminPageService.getAdminPageComponents(this.selectedYear, this.selectedModelType, this.selectedTransmission)).subscribe(resp => {
                this.adminPage = resp;

                if (this.adminPage.errors != null) {
                    this.msg = "No Data available.";
                }
                
                if (this.adminPage.release !== null || this.adminPage.trigger !== null || this.adminPage.invoiceTesting !== null || this.adminPage.posDate !== null) {
                    this.selectedReleaseChoice = this.adminPage.release;
                    this.selectedTriggerChoice = this.adminPage.trigger;
                    this.selectedTestingChoice = this.adminPage.invoiceTesting;
                    this.selectedPOSDate = this.adminPage.posDate == null ? null : new Date(this.adminPage.posDate).toLocaleDateString('en-US');
                }

                if (this.selectedYear <= '2023') {
                    this.adminPage.isUpdateValid = 'N';
                }
                
                this.reportProgress = false;
                this.showFields = true;
            });
        }

        
        setTimeout(() => { this.reportProgress = false }, 500);
    }

    async updateData() {
        this.msg = "";
        this.updateProgress = true;

        if (this.selectedYear != null && this.selectedModelType != null && this.selectedTransmission != null 
            && (this.selectedReleaseChoice != null || this.selectedTriggerChoice != null || this.selectedTestingChoice != null || this.selectedPOSDate != null)) {
            this.updateProgress = false;
            
            this.selectedPOSDate = this.selectedPOSDate == null ? null : new Date(this.selectedPOSDate).toLocaleDateString('en-US');

            this.adminPageService.updateorAddAdminPageComponents({ release: this.selectedReleaseChoice, trigger: this.selectedTriggerChoice, invoiceTesting: this.selectedTestingChoice, posDate: this.selectedPOSDate, modelYear: this.selectedYear, modelType: this.selectedModelType, transmissionID: this.selectedTransmission, userID: this.userID }).then((result) => {
                if (result) {
                    this.msg = "Update complete.";
                    // let button = <HTMLButtonElement> document.getElementById('updateButton');
                    // button.disabled = true;                        
                }
                else
                    this.msg = "Update Failed.";
            });
            setTimeout(() => { this.updateProgress = false }, 500);
        }
    }

    uploadedFiles: any[] = [];

    onUpload(event) {
        for (let file of event.files) {
            this.uploadedFiles.push(file);
        }
        // this.messageService.add({severity: 'info', summary: 'File Uploaded', detail: ''});
    }
}