import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import axios from 'axios';
import { Observable } from 'rxjs';

import { LoginService } from '../core/login.service';
import { EmailProperties } from '../model/emailProperties';

@Injectable({
    providedIn: 'root'
})
export class EmailService {

    constructor(private http: HttpClient, private loginService: LoginService) { }

    public async getEmailProperties(emailType: string): Promise<Observable<EmailProperties>> {
        return this.http.get<EmailProperties>(`${environment.serviceUrl}public/api/getEmailProperties/${emailType}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`
                })
            });
    }

    public async sendEmail(from: string, to: string, subject: string, message: string, cc:string, bcc:string): Promise<boolean> {
        // const userID = this.loginService.getUserSession()['userName'];
        console.log("In Email Service: from: " + from + ",to: " + to + ",cc: " + cc + ",bcc: " + bcc + ",subject: " + subject + ",message: " + message);
        const userID = JSON.parse(sessionStorage.getItem("userFullName"));
        // const from = 'notifications@pcnadashboard.com';
        const finalMessage = message + '<p>' + environment.portalURL + '</p>';
        const result = await axios.post<boolean>(`${environment.serviceUrl}secure/api/sendEmail`,
            { from: from, to: to, subject: subject, message: finalMessage, userID: userID, cc: cc, bcc: bcc },
            {
                headers: {
                    'Authorization': this.getToken(),
                    'Content-Type': 'application/json'
                }
            });
        return result.data;
    }

    protected getToken(): string {
        return 'Bearer ' + sessionStorage.getItem("token");
    }

}
