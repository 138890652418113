import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { LoginService } from '../core/login.service';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'is-what';

import { Model } from '../model/model';
import { LookupDataService } from '../services/lookup-data.service';
import { APIService } from '../services/api.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-labelPreview',
    templateUrl: './labelPreview.component.html',
    styleUrls: ['./labelPreview.component.css']
})
export class PreviewLabelComponent implements OnInit {

    reportProgress: boolean = false;
    labelProgress: boolean = false;

    showFields: boolean = false;

    msg: string = "";

    years: any[];
    modelType: any[];
    transmission: any[];
    ftype: any[];
    options: any[];

    model = new Model('', '', '', '', '');

    selectedVIN: any = "";
    vins: any[];

    selectedYear: string;
    selectedModelType: string;
    selectedTransmission: string;
    selectedFuelType: string;
    selectedOptions: string;

    userID: string;

    constructor(private sanitizer: DomSanitizer,
        public loginService: LoginService,
        private lookupService: LookupDataService,
        private apiService: APIService,
        private router: Router,
        private titleService: Title) {
        this.titleService.setTitle("Preview Label");
        this.getModelYears();
        this.getModelType();
        this.getTransmissions();
        this.getFuelType();
        this.getOptions();
    }


    ngOnInit() {
        const userSession = this.loginService.getUserSession();
        this.userID = this.loginService.getUserSession()['userName'];
        if (isNullOrUndefined(userSession)) {
            this.router.navigate(['login']);
        }
    }

    getModelYears() {
        if (!this.years) {
            this.getLookupData('modelYear').subscribe(resp => {
                this.years = resp;
            });
        }
    }

    getModelType() {
        if (!this.modelType) {
            this.getLookupData('modelType').subscribe(resp => {
                this.modelType = resp;
            });
        }
    }

    getTransmissions() {
        if (!this.transmission) {
            this.getLookupData('transmission').subscribe(resp => {
                this.transmission = resp;
            });
        }
    }

    getFuelType() {
        if (!this.ftype) {
            // this.ftype = [{ field: "GH", value: "Gasoline" },
            // { field: "PHEV", value: "Electricity + Gasoline" },
            // { field: "BEV", value: "Electricity" }
            // ];
            this.getLookupData('fuelType').subscribe(resp => {
                this.ftype = resp;
            });
        }
    }

    getOptions() {
        if (!this.options) {
            this.getLookupData('options').subscribe(resp => {
                this.options = resp;
            });
        }
    }

    getVINs() {
        if (!this.vins) {
            this.getLookupData('vins').subscribe(resp => {
                this.vins = resp;
            });
        }
    }

    getLookupData(type: string) {
        return this.lookupService.getLookupData(type);
    }

    changeYear(event, type) {
        console.log("ID: " + event + ", Type: " + type);

        this.selectedYear = this.model.modelYear;
        if (this.model.modelYear != null) {
            this.lookupService.getModelTypeLookupDataByYear(this.model.modelYear).subscribe(resp => {
                this.modelType = resp;
            });
        }
    }

    changeModelType(event, type) {
        console.log("ID: " + event + ", Type: " + type);

        if (this.modelType != null) {
            for (var i in this.modelType) {
                if (this.model.modelType === this.modelType[i].value) {
                    this.selectedModelType = this.modelType[i].field;
                }
            }
        }

        if (this.selectedYear != null && this.selectedModelType != null) {
            this.lookupService.getTransmissionLookupDataByYearAndType(this.selectedYear, this.selectedModelType).subscribe(resp => {
                this.transmission = resp;
            });
        }
    }

    changeTransmission(event, type) {
        console.log("ID: " + event + ", Type: " + type);

        if (this.transmission != null) {
            for (var i in this.transmission) {
                if (this.model.transmission === this.transmission[i].value) {
                    this.selectedTransmission = this.transmission[i].field;
                }
            }
        }

        if (this.selectedYear != null && this.selectedModelType != null && this.selectedTransmission != null) {
            this.lookupService.getFuelTypeLookupDataByYearTypeAndTransmission(this.model.modelYear, this.selectedModelType, this.selectedTransmission).subscribe(resp => {
                this.ftype = resp;
            });
        }

    }

    changeFuelType(event, type) {
        console.log("ID: " + event + ", Type: " + type);

        if (this.ftype != null) {
            for (var i in this.ftype) {
                if (this.model.fuelType === this.ftype[i].value) {
                    this.selectedFuelType = this.ftype[i].field;
                }
            }
        }

        if (this.selectedYear != null && this.selectedModelType != null && this.selectedTransmission != null && this.selectedFuelType != null) {
            this.lookupService.getOptionsLookupDataByYearTypeTransmAndFuelType(this.model.modelYear, this.selectedModelType, this.selectedTransmission, this.selectedFuelType).subscribe(resp => {
                this.options = resp;
            });
        }
    }

    changeOptions(event, type) {
        console.log("ID: " + event + ", Type: " + type);

        if (this.options != null) {
            for (var i in this.options) {
                if (this.model.options === this.options[i].value) {
                    this.selectedOptions = this.options[i].field;
                }
            }
        }
        this.getPosDate(this.selectedYear, this.selectedModelType, this.selectedTransmission);
    }

    changeVIN(event, type) {
        console.log("ID: " + event + ", Type: " + type);
    }

    clearEntries() {
        this.model.modelType = "";
        this.model.modelYear = "";
        this.model.transmission = "";
        this.model.fuelType = "";
        this.model.options = "";
        this.selectedYear = "";
        this.selectedModelType = "";
        this.selectedTransmission = "";
        this.selectedFuelType = "";
        this.selectedOptions = "";
        this.selectedVIN = "";
        this.msg = "";
        this.posDate = "";
        this.showFields = false;
    }

    posDate: string;
    getPosDate(selectedYear, selectedModelType, selectedTransmission) {
        this.lookupService.getPOSDate(selectedYear, selectedModelType, selectedTransmission).then((posDate) => {
            this.posDate = posDate;
        });
    }

    view() {
        this.showFields = true;
        this.viewLabel();
    }

    async viewLabel() {
        this.labelProgress = true;
        this.msg = "";

        // if(this.selectedYear == '2024' && this.selectedModelType == '982320' && this.selectedTransmission == '2207' && this.selectedFuelType == 'GV' && this.selectedOptions == 'DEFAULT' && this.selectedVIN == 'WP0CA2A80RK205149') {  //GV, DEFAULT
        //     window.open('../assets/Full_Label_2024_718_Boxster.pdf', '_blank');
        // } else if (this.selectedYear == '2024' && this.selectedModelType == '982110' && this.selectedTransmission == '2202' && this.selectedFuelType == 'GV' && this.selectedOptions == 'DEFAULT' && this.selectedVIN == 'WP0AA2A80RK255086') {   //GV, DEFAULT
        //     window.open('../assets/Full_Label_Cayman_Style_Edition.pdf', '_blank');
        // } else if (this.selectedYear == '2024' && this.selectedModelType == '95BBV1' && this.selectedTransmission == '2200' && this.selectedFuelType == 'GV' && this.selectedOptions == 'DEFAULT' && this.selectedVIN == 'WP1AG2A50RLB35012') {  //GV, DEFAULT
        //     window.open('../assets/Full_Label_MacanS.pdf', '_blank');
        // } else if (this.selectedYear == '2024' && this.selectedModelType == '992110' && this.selectedTransmission == '2211' && this.selectedFuelType == 'GV' && this.selectedOptions == 'DEFAULT' && this.selectedVIN == 'WP0AA2A90RS207175') {  //GV, DEFAULT
        //     window.open('../assets/Full_Label_911_Carrera.pdf', '_blank');
        // } else if (this.selectedYear == '2024' && this.selectedModelType == 'Y1AAA1' && this.selectedTransmission == '2250' && this.selectedFuelType == 'BEV' && this.selectedOptions == 'J9K' && this.selectedVIN == 'WP0AA2Y10RSA10008') {  //BEV, J9K
        //     window.open('../assets/Full_Label_Taycan_2Speed_BEV.pdf', '_blank');
        // }
        // setTimeout(() => { this.labelProgress = false }, 500);

        await this.apiService.getFullLabelProof(environment.combinedElementsTemplateID, this.selectedYear, this.selectedModelType, this.selectedTransmission, this.selectedVIN, this.selectedFuelType, this.selectedOptions).subscribe((res) => {
            console.log(res);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(res);
            var dd = new Date;
            var date = new Date().getFullYear() + "-" + (dd.getMonth() + 1) + "-" + dd.getDate();
            var seconds = dd.getTime() / 1000;
            var fileName = "Full_Label_Preview" + this.selectedYear + "_" + this.selectedModelType + "_" + date + "_" + seconds.toString() + ".pdf";
            // link.setAttribute('download', fileName);
            link.setAttribute('target', '_blank');
            link.click();
            this.labelProgress = false;
        }), error => {
            console.log("Error occured with the API response: " + error);
        };

        
    }
}
