<main class="main">
        <div class="main-content" style="padding: 10px;">
                <h3>Fuel Economy & Environment</h3>
                <form class="report-form">
                        <div class="row">
                                <div class="form-group col-sm-2">
                                        <label for="year">Model Year</label>
                                        <datalist id="year" name="year">
                                                <option *ngFor="let yr of years; let i = index;" [value]="yr.field">
                                                        {{yr.value}}</option>
                                        </datalist>
                                        <input autocomplete="on" class="form-control" placeholder="Select the Year"
                                                id="year" name="year" #select list="year" [(ngModel)]="model.modelYear"
                                                [ngModelOptions]="{standalone: true}"
                                                (ngModelChange)="changeYear($event, 'year')" />
                                </div>

                                <div class="form-group col-sm-2">
                                        <label for="modelType">Model Type</label>
                                        <datalist id="modelType" name="modelType">
                                                <option *ngFor="let model of modelType; let i = index;"
                                                        [value]="model.value">{{model.value}}
                                                </option>
                                        </datalist>
                                        <input autocomplete="on" class="form-control"
                                                placeholder="Select the Model Type" id="modelType" name="modelType"
                                                #select list="modelType" [(ngModel)]="model.modelType"
                                                [ngModelOptions]="{standalone: true}"
                                                (ngModelChange)="changeModelType($event, 'type')" />
                                </div>

                                <div class="form-group col-sm-2">
                                        <label for="transmission">Transmission</label>
                                        <datalist id="transmission" name="transmission">
                                                <option *ngFor="let trns of transmission; let i = index;"
                                                        [value]="trns.value">{{trns.value}}
                                                </option>
                                        </datalist>
                                        <input autocomplete="on" class="form-control"
                                                placeholder="Select the Transmission" id="transmission"
                                                name="transmission" #select list="transmission"
                                                [(ngModel)]="model.transmission" [ngModelOptions]="{standalone: true}"
                                                (ngModelChange)="changeTransmission($event, 'transmission')" />
                                </div>

                                <div class="form-group col-sm-2">
                                        <label for="fuelType">Fuel Type</label>
                                        <datalist id="fuelType" name="fuelType">
                                                <option *ngFor="let ft of ftype; let i = index;" [value]="ft.value">
                                                        {{ft.value}}</option>
                                        </datalist>
                                        <input autocomplete="on" class="form-control" placeholder="Select Fuel Type"
                                                id="fuelType" name="fuelType" #select list="fuelType"
                                                [(ngModel)]="model.fuelType" [ngModelOptions]="{standalone: true}"
                                                (ngModelChange)="changeFuelType($event, 'fuelType')" />
                                </div>

                                <div class="form-group col-sm-2">
                                        <label for="options">Options</label>
                                        <datalist id="options" name="options">
                                                <option *ngFor="let opt of options; let i = index;" [value]="opt.value">
                                                        {{opt.value}}</option>
                                        </datalist>
                                        <input autocomplete="on" class="form-control" placeholder="Select options"
                                                id="options" name="options" #select list="options"
                                                [(ngModel)]="model.options" [ngModelOptions]="{standalone: true}"
                                                (ngModelChange)="changeOptions($event, 'options')" />
                                </div>

                                <div class="form-group col-sm report-button-container">
                                        <button [disabled]="!model.modelYear || !model.modelType || !model.transmission || !model.fuelType || !model.options"
                                                class="btn btn-primary" type="submit" iconPos="right"
                                                (click)="view()">View <em *ngIf="reportProgress"
                                                        class="fas fa-spinner fa-spin"></em></button>
                                        &nbsp;&nbsp;
                                        <button class="btn btn-primary" type="submit" iconPos="right"
                                                (click)="clearEntries()">Clear All
                                                Filters</button>
                                </div>
                        </div>
                        <div *ngIf="msg.length>0" [hidden]="msg.length<1"
                                class="alert alert-danger alert-dismissible fade show w-50" role="alert">
                                {{msg}}
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                </button>
                        </div>

                </form>

                <div class="container-fluid" *ngIf="showFields">
                        <h5>Click on Approve, if the fields are correct </h5>
                        <div class="report-button-container">
                                <button class="btn btn-primary" type="submit" id="approveButton" iconPos="right"
                                        [disabled]="fuelEconomy.approveFlag === 'Y'" (click)="approveData()">Approve
                                        Data <em *ngIf="approveProgress" class="fas fa-spinner fa-spin"></em></button>
                        </div>
                        <div style="text-align: right;" class="report-button-container">
                                <button id="labelButton" class="btn btn-primary" type="submit" iconPos="right"
                                (click)="viewLabel()">View Label Proof <em *ngIf="labelProgress"
                                        class="fas fa-spinner fa-spin"></em></button>
                        </div>
                        <h5 style="text-align: right;">Point of Sale Date: {{posDate}}</h5>
                        <table>
                                <tbody>
                                        <tr>
                                                <td>Combined Fuel Economy</td>
                                                <td><input name="combinedFuelEconomy" type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.combinedFuelEconomy"
                                                                value="{{fuelEconomy.combinedFuelEconomy}}"></td>

                                                <td *ngIf="selectedFuelType != 'PIH-B'">City Fuel Economy</td>
                                                <td *ngIf="selectedFuelType != 'PIH-B'"><input name="cityFuelEconomy"
                                                                type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.cityFuelEconomy"
                                                                value="{{fuelEconomy.cityFuelEconomy}}"></td>

                                                <td *ngIf="selectedFuelType != 'PIH-B'">Hwy Fuel Economy</td>
                                                <td *ngIf="selectedFuelType != 'PIH-B'"><input name="hwyFuelEconomy"
                                                                type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.hwyFuelEconomy"
                                                                value="{{fuelEconomy.hwyFuelEconomy}}"></td>

                                                <td>Fuel Consumption</td>
                                                <td><input name="fuelConsumption" type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.fuelConsumption"
                                                                value="{{fuelEconomy.fuelConsumption}}"></td>
                                        </tr>
                                        <tr>
                                                <td>Vehicle Type</td>
                                                <td><input name="vehicleType" type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.vehicleType"
                                                                value="{{fuelEconomy.vehicleType}}"></td>

                                                <td>Vehicle Type Range</td>
                                                <td><input name="vehicleTypeRange" type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.vehicleTypeRange"
                                                                value="{{fuelEconomy.vehicleTypeRange}}">
                                                </td>

                                                <td>Vehicle Type Metric</td>
                                                <td><input name="vehicleTypeRangeMetric" type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.vehicleTypeRangeMetric"
                                                                value="{{fuelEconomy.vehicleTypeRangeMetric}}"></td>

                                                <td>Annual Fuel Cost Savings</td>
                                                <td><input name="annualFuelCostSavings" type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.annualFuelCostSavings"
                                                                value="{{fuelEconomy.annualFuelCostSavings}}"></td>
                                        </tr>
                                        <tr>
                                                <td>Annual Fuel Cost</td>
                                                <td><input name="annualFuelCost" type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.annualFuelCost"
                                                                value="{{fuelEconomy.annualFuelCost}}"></td>

                                                <td>Cost Per Gallon</td>
                                                <td><input name="costPerGallon" type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.costPerGallon"
                                                                value="{{fuelEconomy.costPerGallon}}"></td>

                                                <td>CO2 Value</td>
                                                <td><input name="c02Value" type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.c02Value"
                                                                value="{{fuelEconomy.c02Value}}"></td>

                                                <td>Smog Rating</td>
                                                <td><input name="smogRating" type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.smogRating"
                                                                value="{{fuelEconomy.smogRating}}"></td>
                                        </tr>
                                        <tr>
                                                <td>FE Rating</td>
                                                <td><input name="feRating" type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.feRating"
                                                                value="{{fuelEconomy.feRating}}"></td>

                                                <td *ngIf="selectedFuelType === 'PIH-B' || selectedFuelType === 'BEV'">
                                                        Alt Fuel Type</td>
                                                <td *ngIf="selectedFuelType === 'PIH-B' || selectedFuelType === 'BEV'">
                                                        <input name="altFuelType" type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.altFuelType"
                                                                value="{{fuelEconomy.altFuelType}}">
                                                </td>

                                                <td *ngIf="selectedFuelType === 'PIH-B'">Alt Fuel Economy</td>
                                                <td *ngIf="selectedFuelType === 'PIH-B'"><input name="altFuelEconomy"
                                                                type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.altFuelEconomy"
                                                                value="{{fuelEconomy.altFuelEconomy}}"></td>

                                                <td *ngIf="selectedFuelType === 'PIH-B'">Alt Fuel Consumption</td>
                                                <td *ngIf="selectedFuelType === 'PIH-B'"><input
                                                                name="altFuelConsumption" type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.altFuelConsumption"
                                                                value="{{fuelEconomy.altFuelConsumption}}"></td>
                                        </tr>
                                        <tr *ngIf="selectedFuelType === 'PIH-B' || selectedFuelType === 'BEV'">
                                                <td>Charge Time</td>
                                                <td><input name="chargeTime" type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.chargeTime"
                                                                value="{{fuelEconomy.chargeTime}}"></td>

                                                <td>Alt Cost Per</td>
                                                <td><input name="altCostPer" type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.altCostPer"
                                                                value="{{fuelEconomy.altCostPer}}"></td>

                                                <td>Range</td>
                                                <td><input name="range" type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.range"
                                                                value="{{fuelEconomy.range}}"></td>

                                                <td *ngIf="selectedFuelType === 'PIH-B'">Alt Range</td>
                                                <td *ngIf="selectedFuelType === 'PIH-B'"><input name="altRange"
                                                                type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.altRange"
                                                                value="{{fuelEconomy.altRange}}"></td>
                                        </tr>
                                        <tr *ngIf="selectedFuelType === 'PIH-B' || selectedFuelType === 'BEV'">
                                                <td>Subpoint_1</td>
                                                <td><input name="subpoint1" type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.subpoint1"
                                                                value="{{fuelEconomy.subpoint1}}"></td>

                                                <td>Subpoint_2</td>
                                                <td><input name="subpoint2" type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.subpoint2"
                                                                value="{{fuelEconomy.subpoint2}}"></td>

                                                <td>Subpoint_3</td>
                                                <td><input name="subpoint3" type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.subpoint3"
                                                                value="{{fuelEconomy.subpoint3}}"></td>

                                                <td>Subpoint_4</td>
                                                <td><input name="subpoint4" type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.subpoint4"
                                                                value="{{fuelEconomy.subpoint4}}"></td>
                                        </tr>
                                        <tr>
                                                <td>CO2_Rating</td>
                                                <td><input name="c02Rating" type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.c02Rating"
                                                                value="{{fuelEconomy.c02Rating}}"></td>

                                                <td>QR Code URL</td>
                                                <td colspan="3"><input name="qrCode" type="text" class="inputIncrease"
                                                                [(ngModel)]="fuelEconomy.qrCode"
                                                                value="{{fuelEconomy.qrCode}}"></td>

                                                <td>Gas Guzzler Tax</td>
                                                <td><input name="gasGuzzlerTax" type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.gasGuzzlerTax"
                                                                value="{{fuelEconomy.gasGuzzlerTax}}"></td>
                                        </tr>
                                        <tr *ngIf="selectedFuelType === 'PIH-B' || selectedFuelType === 'BEV'">
                                                <td>Convertible_Flag</td>
                                                <td><input name="convertibleFlag" type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.convertibleFlag"
                                                                value="{{fuelEconomy.convertibleFlag}}"></td>

                                                <td *ngIf="selectedFuelType === 'PIH-B'">Alt_Fuel_Consumption_1</td>
                                                <td *ngIf="selectedFuelType === 'PIH-B'"><input
                                                                name="altFuelConsumption1" type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.altFuelConsumption1"
                                                                value="{{fuelEconomy.altFuelConsumption1}}"></td>

                                                <td *ngIf="selectedFuelType === 'PIH-B'">Alt_Fuel_Consumption_2</td>
                                                <td *ngIf="selectedFuelType === 'PIH-B'"><input
                                                                name="altFuelConsumption2" type="text" class="validate"
                                                                [(ngModel)]="fuelEconomy.altFuelConsumption2"
                                                                value="{{fuelEconomy.altFuelConsumption2}}"></td>
                                        </tr>
                                        <tr>
                                                <td colspan="7"></td>
                                                <td style="text-align: right;"><button class="btn btn-primary"
                                                                id="updateButton" type="submit"
                                                                [disabled]="fuelEconomy.updateFlag === 'Y'"
                                                                iconPos="right" (click)="updateData()">Update <em
                                                                        *ngIf="updateProgress"
                                                                        class="fas fa-spinner fa-spin"></em></button>
                                                </td>
                                        </tr>
                                </tbody>
                        </table>
                </div>
        </div>
</main>