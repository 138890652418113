<main class="main">
    <div class="main-content" style="padding: 10px;">
        <h3>Dashboard</h3>
        <form class="report-form">
            <div class="row">
                <div class="form-group col-sm-2">
                    <label for="year">Model Year</label>
                    <datalist id="year" name="year">
                        <option *ngFor="let yr of years; let i = index;" [value]="yr.field">{{yr.value}}</option>
                    </datalist>
                    <input autocomplete="on" class="form-control" placeholder="Select the Year" id="year" name="year"
                        #select list="year" [(ngModel)]="model.modelYear" [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="changeYear($event, 'year')" />
                </div>

                <div class="form-group col-sm-2">
                    <label for="modelType">Model Type/ Description</label>
                    <datalist id="modelType" name="modelType">
                        <option *ngFor="let model of modelType; let i = index;" [value]="model.value">{{model.value}}
                        </option>
                    </datalist>
                    <input autocomplete="on" class="form-control" placeholder="Select the Model Type" id="modelType"
                        name="modelType" #select list="modelType" [(ngModel)]="model.modelType"
                        [ngModelOptions]="{standalone: true}" (ngModelChange)="changeModelType($event, 'type')" />
                </div>

                <div class="form-group col-sm-2">
                    <label for="transmission">Transmission</label>
                    <datalist id="transmission" name="transmission">
                        <option *ngFor="let trns of transmission; let i = index;" [value]="trns.value">{{trns.value}}
                        </option>
                    </datalist>
                    <input autocomplete="on" class="form-control" placeholder="Select the Transmission"
                        id="transmission" name="transmission" #select list="transmission"
                        [(ngModel)]="model.transmission" [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="changeTransmission($event, 'transmission')" />
                </div>

                <div class="form-group col-sm report-button-container">
                    <button [disabled]="!model.modelYear" class="btn btn-primary" type="submit" iconPos="right"
                        (click)="view()">View <em *ngIf="reportProgress" class="fas fa-spinner fa-spin"></em></button>
                    &nbsp;&nbsp;
                    <button class="btn btn-primary" type="submit" iconPos="right" (click)="clearEntries()">Clear All
                        Filters</button>
                </div>
            </div>
            <div *ngIf="msg.length>0" [hidden]="msg.length<1"
                class="alert alert-danger alert-dismissible fade show w-50" role="alert">
                {{msg}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

        </form>

        <div class="container-fluid" *ngIf="viewReport">
            <div *ngIf="reportProgress" class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <span>No. of Records: {{dataTable.records.length}}</span>

            <p-table [columns]="cols" [value]="dataTable.records" [paginator]="true" [rows]="10" responsiveLayout="stack"
                [breakpoint]="'960px'" styleClass="p-datatable-striped" [tableStyle]="{ 'min-width': '50rem' }"                
                [rowsPerPageOptions]="[10, 20, 30, 40, 50]">
                                
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of dataTable.columns; index as i;">
                            {{col.header}}                            
                        </th>
                    </tr>
                </ng-template>
                
                <ng-template pTemplate="body" let-rowData let-columns="columns" *ngIf="dataTable.records.length>0">
                    <tr>
                        <td *ngFor="let col of dataTable.columns; index as i;">
                            <span *ngIf="col.dataType!='number'">{{rowData[col.field]}}</span>
                            <span *ngIf="col.dataType=='number'">{{rowData[col.field] | currency:'USD':'symbol'}}</span>
                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData let-columns="columns" *ngIf="dataTable.records.length==0">
                    <tr>
                        <td scope="row" class="text-center">
                            No data available.
                        </td>
                    </tr>
                </ng-template>
            </p-table>

            <!-- <br>
            <hr>
            <table *ngIf="dataTable" class="table" [paginator]="true" [rows]="5" [tableStyle]="{ 'min-width': '50rem' }">
                <thead class="thead-light">
                    <tr>
                        <th [ngClass]="{'table-title': i===0, 'table-header': i !== 0}"
                            [style.width]="width == null ? '' : width[i]"
                            *ngFor="let column of dataTable.columns; index as i;" scope="col">
                            {{column.header}}
                        </th>
                    </tr>
                </thead>
                <tbody class="table-text">
                    <ng-container *ngIf="dataTable.records.length > 0">
                        <tr *ngFor="let row of dataTable.records; index as i2;">
                            <td scope="row" id="{{column.dataType}}" [ngClass]="{'center-text' : i!==0}"
                                [style.width]="width == null ? '' : width[i]"
                                *ngFor="let column of dataTable.columns; index as i;">
                                <span *ngIf="column.dataType!='number'">{{row[column.field]}}</span>
                                <span *ngIf="column.dataType=='number'">{{row[column.field] |
                                    currency:'USD':'symbol'}}</span>
                            </td>
                        </tr>
                    </ng-container>

                    <tr>
                        <ng-container *ngIf="dataTable.records.length==0">
                            <td scope="row" class="text-center">
                                No data available.
                            </td>
                        </ng-container>
                    </tr>
                </tbody>
            </table> -->
        </div>
    </div>
</main>